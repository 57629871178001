import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { WeekdayEmptyStoryIcon } from 'public/assets';
import { css } from '@emotion/react';
import theme from '@styles/theme';

const HomeTabStoryEmptyStory = () => {
  const { t } = useTranslation();

  return (
    <div css={text}>
      <WeekdayEmptyStoryIcon />
      <p className="centent">
        {t('common_screen_label_message_not_exist_weekday_story')}
      </p>
    </div>
  );
};

const text = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6.75rem 0;

  .centent {
    margin-top: 0.75rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: center;
    letter-spacing: -0.0187rem;
    color: ${theme.colors.gray500};
  }
`;

export default HomeTabStoryEmptyStory;
