import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import {
  AdultLabelIcon,
  HomeTabTitleUpIcon,
  HomeTabTitlePauseIcon,
} from 'public/assets';
import { getStatusText, getLabelText } from '@common/utils';
import { Story_Play_Type } from '@/baseType';
import { useAppDispatch } from '@store/hooks';
import { setPreviousSection } from '@slices/eventSlice';
import { HOME_TAB_TYPE } from '@customTypes/home';
import { StoryVerticalCoverDtoFragment } from '@/operations/fragments/__generated__/home.generated';

import SPImage from '@components/commons/image/SPImage';

import StoryItemLink from '../../../common/storyItem/link/StoryItemLink';

interface IAdultTabStoryItem {
  index: number;
  data: StoryVerticalCoverDtoFragment;
  hasTitle?: boolean;
}

const AdultTabStoryItem = ({ data, hasTitle, index }: IAdultTabStoryItem) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const setEventParams = () => {
    dispatch(setPreviousSection(HOME_TAB_TYPE.Adult));
  };

  return (
    <div
      css={wrapper}
      data-name={data.name}
      data-index={index}
      data-id={data.storyId}
      data-label={getLabelText({
        currentHomeTab: HOME_TAB_TYPE.Adult,
        isFinished: data.isFinished,
        isOnHiatus: data.onHiatus,
        isNew: data.isNew,
        isUp: data.isUp,
      })}
      data-status={getStatusText(data)}
      data-content-type={
        data.playType === Story_Play_Type.Epub ? 'novel' : 'interactive'
      }
    >
      <StoryItemLink
        storyId={data.storyId}
        isAdult={data.isAdult}
        setEventParams={setEventParams}
      >
        <div className="thumbnail">
          <SPImage
            src={data.mainImageFile?.link || ''}
            layout="fill"
            alt={data.name}
          />
          {data.isAdult && (
            <div className="adult-sign">
              <AdultLabelIcon width={24} height={24} />
            </div>
          )}
        </div>
        <>
          {hasTitle && (
            <>
              <div css={container}>
                <div className="title-wrapper">
                  <span>
                    {data?.isUp ? (
                      <HomeTabTitleUpIcon />
                    ) : (
                      data.onHiatus && <HomeTabTitlePauseIcon />
                    )}
                  </span>
                  <h2 className="title">{data?.name}</h2>
                </div>
                <div className="genre">
                  <span>{t(data?.genreKey?.text.key ?? '')} ・ </span>{' '}
                  <span>{data.authorName}</span>
                </div>
              </div>
            </>
          )}
        </>
      </StoryItemLink>
    </div>
  );
};

const FIVE_ITEMS_MAX_WIDTH = '800px';
const FOUR_ITEMS_MAX_WIDTH = '610px';
const THREE_ITEMS_MAX_WIDTH = '490px';

const wrapper = css`
  display: flex;
  flex-direction: column;
  width: 7.8125rem;
  text-align: left;
  cursor: pointer;

  .thumbnail {
    position: relative;
    width: 7.8125rem;
    height: 11.875rem;
    border: 0.0625rem solid ${theme.colors.gray200};
    border-radius: 0.22rem;

    img {
      border-radius: 0.22rem;
      image-rendering: auto;
    }

    .adult-sign {
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
    }
  }

  .event-wrapper {
    position: relative;
    display: flex;
    align-items: flex-end;
    padding: 0.25rem;
    height: 100%;
  }

  .event {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0.375rem 0.25rem 0.25rem;
    background: ${theme.colors.primary500};
    color: ${theme.colors.white};
    font-weight: 600;
    font-size: 0.5625rem;
    letter-spacing: -0.0313rem;
    border-radius: 0.125rem;
  }

  // 30px: 양 옆 마진
  @media screen and (max-width: ${FIVE_ITEMS_MAX_WIDTH}) {
    width: 100%;

    .thumbnail {
      width: calc((100vw - 30px) / 5);
      height: calc(((100vw - 30px) / 5) * 1.5);
    }
  }

  @media screen and (max-width: ${FOUR_ITEMS_MAX_WIDTH}) {
    width: 100%;

    .thumbnail {
      width: calc((100vw - 30px) / 4);
      height: calc(((100vw - 30px) / 4) * 1.5);
    }
  }

  @media screen and (max-width: ${THREE_ITEMS_MAX_WIDTH}) {
    width: 100%;

    .thumbnail {
      width: calc((100vw - 30px) / 3);
      height: calc(((100vw - 30px) / 3) * 1.5);
    }
  }
`;

const container = css`
  margin-top: 0.5rem;
  width: 100%;

  .title-wrapper {
    display: flex;

    svg {
      margin: 0 0.25rem 0.0375rem 0;
    }
  }

  /* 줄바꿈 문자 */
  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    word-wrap: break-word;
    height: 1.25rem;
    white-space: nowrap;
    color: ${theme.colors.gray800};
    ${theme.font.header.subhead1}
  }

  .genre {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    word-wrap: break-word;
    height: 1.125rem;
    white-space: nowrap;
    overflow: hidden;
    ${theme.font.body.caption}
    color: ${theme.colors.gray500};
  }
`;

export default AdultTabStoryItem;
