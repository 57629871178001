import React from 'react';
import StoryItemPlaceHolder from '@components/commons/loading/StoryItemPlaceHolder';
import StoryGrid from '../../common/StoryGrid';
import { Section } from '@/customTypes/event';

const StorySkeletonGrid = ({ section }: { section?: Section }) => {
  return (
    <StoryGrid section={section}>
      {new Array(6).fill('').map((_, index) => (
        <StoryItemPlaceHolder key={index} />
      ))}
    </StoryGrid>
  );
};

export default StorySkeletonGrid;
