import { gql } from '@apollo/client';
import { STORY_VERTICAL_COVER_DTO } from '@/operations/fragments/home';
import {
  GenreStoryListQueryVariables,
  useGenreStoryListQuery as useQuery,
} from './__generated__/useGenreStoryList.generated';

export const GENRE_STORY_LIST = gql`
  query GenreStoryList($page: Int, $playType: STORY_PLAY_TYPE, $genre: String) {
    listStoryByWebV3(
      data: { page: $page, playType: $playType, pageSize: 15, genre: $genre }
    ) {
      totalCount
      page
      pageSize
      list {
        ...StoryVerticalCoverDto
      }
    }
  }
  ${STORY_VERTICAL_COVER_DTO}
`;

export const useGenreStoryListQuery = (
  variables?: GenreStoryListQueryVariables,
) => {
  const { data, fetchMore, networkStatus, loading } = useQuery({
    variables,
  });

  const hasNext =
    data &&
    data?.listStoryByWebV3.totalCount > data?.listStoryByWebV3.list.length;

  return { data, fetchMore, hasNext, networkStatus, loading };
};
