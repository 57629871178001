import React from 'react';
import { Direct_Ads_Type, Story_Play_Type } from '@/baseType';

import DirectAdsBannerWrapper from '@/components/main/common/banner/DirectAdsBannerWrapper';
import { useAdultStoryListQuery } from '@/operations/queries/home/useAdultStoryList';
import HomeTabStoryEmptyStory from './item/HomeTabStoryEmptyStory';
import AdultTabStoryGrid from './item/AdultTabStoryGrid';
import StorySkeletonGrid from '../grid/StorySkeletonGrid';

interface IAdultContainer {
  playType?: Story_Play_Type;
}

const AdultContainer = ({ playType }: IAdultContainer) => {
  const { data, hasNext, fetchMore, networkStatus, loading } =
    useAdultStoryListQuery({
      playType,
    });

  return (
    <>
      <DirectAdsBannerWrapper
        type={Direct_Ads_Type.Home}
        section="홈"
        location={'홈탭-후방주의_배너'}
      />
      {loading ? (
        <StorySkeletonGrid />
      ) : (
        <>
          {data?.listStoryByWebV3.list ? (
            <AdultTabStoryGrid
              data={data}
              fetchMore={fetchMore}
              hasNext={!!hasNext}
              nextPage={Number(data?.listStoryByWebV3.page) + 1}
              networkStatus={networkStatus}
            />
          ) : (
            <HomeTabStoryEmptyStory />
          )}
        </>
      )}
    </>
  );
};

export default AdultContainer;
