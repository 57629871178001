import React, { useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import { NetworkStatus } from '@common/values';
import { WeekDayTab } from '@customTypes/story';
import { SPLoading } from '@components/commons';
import useInfiniteScroll from '@hooks/useInfiniteScroll';
import { AdultStoryListQuery } from '@/operations/queries/home/__generated__/useAdultStoryList.generated';
import { getWeekDaySectionName } from '@common/utils';

import StoryGrid from '../../../common/StoryGrid';
import GenreTabStoryItem from './GenreTabStoryItem';

interface IGenreTabStoryGrid {
  data?: AdultStoryListQuery;
  fetchMore: (options: { variables: { page: number } }) => void;
  hasNext: boolean;
  networkStatus: number;
  nextPage: number;
}

const GenreTabStoryGrid = ({
  data,
  fetchMore,
  networkStatus,
  hasNext,
  nextPage,
}: IGenreTabStoryGrid) => {
  const router = useRouter();
  const fetchMoreRef = useRef<HTMLDivElement>(null);
  const { week } = router.query;

  const intersecting = useInfiniteScroll(fetchMoreRef);

  const fetchNextPage = () => {
    if (!data) return;

    fetchMore({
      variables: {
        page: nextPage,
      },
    });
  };

  useEffect(() => {
    if (intersecting && hasNext) {
      fetchNextPage();
    }
  }, [intersecting, hasNext]);

  return (
    <StoryGrid section={getWeekDaySectionName(week as WeekDayTab)}>
      <>
        {data?.listStoryByWebV3.list.map((item, index) => (
          <GenreTabStoryItem
            index={index}
            key={item.storyId}
            data={item}
            hasTitle
          />
        ))}
        {networkStatus === NetworkStatus.loading && <SPLoading />}
        <div ref={fetchMoreRef} />
      </>
    </StoryGrid>
  );
};

export default GenreTabStoryGrid;
