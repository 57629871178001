import { gql } from '@apollo/client';
import { STORY_VERTICAL_COVER_DTO } from '@/operations/fragments/home';
import {
  AdultStoryListQueryVariables,
  useAdultStoryListQuery as useQuery,
} from './__generated__/useAdultStoryList.generated';

export const ADULT_STORY_LIST = gql`
  query AdultStoryList(
    $page: Int
    $playType: STORY_PLAY_TYPE
    $sorting: STORY_SORTING
  ) {
    listStoryByWebV3(
      data: {
        page: $page
        playType: $playType
        pageSize: 15
        uiSectionType: Adult
        sorting: $sorting
      }
    ) {
      totalCount
      page
      pageSize
      list {
        ...StoryVerticalCoverDto
      }
    }
  }
  ${STORY_VERTICAL_COVER_DTO}
`;

export const useAdultStoryListQuery = (
  variables?: AdultStoryListQueryVariables,
) => {
  const { data, fetchMore, networkStatus, loading } = useQuery({
    variables,
  });

  const hasNext =
    data &&
    data?.listStoryByWebV3.totalCount > data?.listStoryByWebV3.list.length;

  return { data, fetchMore, hasNext, networkStatus, loading };
};
