import React from 'react';
import { Direct_Ads_Type, Story_Play_Type } from '@/baseType';

import DirectAdsBannerWrapper from '@/components/main/common/banner/DirectAdsBannerWrapper';
import HomeTabStoryEmptyStory from './item/HomeTabStoryEmptyStory';
import GenreTabStoryGrid from './item/GenreTabStoryGrid';
import StorySkeletonGrid from '../grid/StorySkeletonGrid';
import { useGenreStoryListQuery } from '@/operations/queries/home/useGenreStoryList';
import { useRouter } from 'next/router';

interface IGenreContainer {
  playType?: Story_Play_Type;
  genreType: string;
  genreName: string;
}

const GenreContainer = ({
  playType,
  genreType,
  genreName,
}: IGenreContainer) => {
  const router = useRouter();
  const { data, hasNext, fetchMore, networkStatus, loading } =
    useGenreStoryListQuery({
      playType,
      genre: genreType,
    });

  return (
    <>
      <DirectAdsBannerWrapper
        type={Direct_Ads_Type.Home}
        section="홈"
        location={
          router.pathname.includes('interactive')
            ? `인터랙티브탭-${genreName}_배너`
            : router.pathname.includes('webnovel')
            ? `웹소설탭-${genreName}_배너`
            : `홈탭-${genreName}_배너`
        }
      />
      {loading ? (
        <StorySkeletonGrid />
      ) : (
        <>
          {data?.listStoryByWebV3.list ? (
            <GenreTabStoryGrid
              data={data}
              fetchMore={fetchMore}
              hasNext={!!hasNext}
              nextPage={Number(data?.listStoryByWebV3.page) + 1}
              networkStatus={networkStatus}
            />
          ) : (
            <HomeTabStoryEmptyStory />
          )}
        </>
      )}
    </>
  );
};

export default GenreContainer;
