import { gql } from '@apollo/client';

export const STORY_VERTICAL_COVER_DTO = gql`
  fragment StoryVerticalCoverDto on Story {
    type
    storyId
    name
    authorName
    onHiatus
    isFinished
    isNew
    isUp
    isTimeLeapFree
    playType
    representedAt
    sectionType
    playType
    isAdult
    mainImageFile {
      link
    }
    storyWeekdays {
      weekday
    }
    genreKey {
      text {
        key
      }
    }
    language {
      display
      code
    }
  }
`;
export const STORY_HORIZONTAL_COVER_DTO = gql`
  fragment StoryHorizontalCoverDto on Story {
    storyId
    name
    oneLineDesc
    authorName
    numViews
    numLikes
    numComment
    onHiatus
    isFinished
    isUp
    isAdult
    isTimeLeapFree
    playType
    mainImageFile {
      link
    }
    genreKey {
      text {
        key
        ko_KR
      }
    }
  }
`;

export const GENRE_INFO_DTO = gql`
  fragment GenreInfoDto on StoryGenre {
    type
    text {
      key
      ko_KR
    }
    title {
      key
      ko_KR
    }
  }
`;
